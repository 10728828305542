/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'dot': {
    width: 2,
    height: 2,
    viewBox: '0 0 2 2',
    data: '<path pid="0" d="M0 0h2v2H0z" _fill="#154F4A" fill-rule="evenodd"/>'
  }
})
